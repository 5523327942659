<mat-card class="mat-elevation-z0">
    <mat-card-content>
        <form [formGroup]="form">
            <div class="select-supplier">
                <div class="mat-headline-6">Lieferanten auswählen</div>

                <dqn-form-field class="w-full">
                    <dqn-label>Lieferanten auswählen</dqn-label>

                    <dqn-combobox
                      formControlName="supplierId"
                      [options]="(suppliers$ | async)" />
                </dqn-form-field>
            </div>
        </form>

        <div class="create-supplier">
            <mat-expansion-panel
              class="mat-elevation-z0"
              [expanded]="false">
                <mat-expansion-panel-header>
                    <div class="mat-headline-6">Neuen Lieferant anlegen</div>
                </mat-expansion-panel-header>

                <app-supplier-form
                  [formMode]="FormMode.proposal"
                  (supplierChanged)="onSupplierFormChanged($event)"
                  [disabled]="disableCreateSupplier">
                </app-supplier-form>

                <div class="flex justify-end mt-2">
                    <button
                      mat-raised-button
                      color="primary"
                      (click)="proposal()"
                      [disabled]="!newSupplier">
                        Lieferanten vorschlagen
                    </button>
                </div>
            </mat-expansion-panel>
        </div>
    </mat-card-content>
</mat-card>
