<mat-card class="mat-elevation-z0">
    <mat-card-content>
        <form [formGroup]="form">
            <div class="select-supplier">
                <div class="mat-headline-6">Produkt auswählen</div>

                <dqn-form-field class="w-full">
                    <dqn-label>Produkt auswählen</dqn-label>

                    <dqn-combobox
                      formControlName="productId"
                      [options]="(products$ | async)" />
                </dqn-form-field>
            </div>
        </form>

        <div class="create-supplier">
            <mat-expansion-panel
              class="mat-elevation-z0"
              [expanded]="false">
                <mat-expansion-panel-header>
                    <div class="mat-headline-6">Neues Produkt anlegen</div>
                </mat-expansion-panel-header>

                <app-product-form
                  [formMode]="FormMode.proposal"
                  [disabled]="disableCreateProduct"
                  (submitForm)="createProduct($event)">
                </app-product-form>
            </mat-expansion-panel>
        </div>

        <mat-divider></mat-divider>

        <div class="mt-2">
            <mat-expansion-panel
              class="mat-elevation-z0"
              [expanded]="true">
                <mat-expansion-panel-header>
                    <div class="mat-headline-6">Bestellinformationen</div>
                </mat-expansion-panel-header>

                <form
                  [formGroup]="orderingInformationForm"
                  class="flex flex-col gap-1">
                    <dqn-form-field>
                        <dqn-label>Artikelnummer</dqn-label>
                        <input
                          dqnInput
                          formControlName="supplierOrderNumber"
                          required />
                    </dqn-form-field>

                    <div
                      class="flex gap-1"
                      formGroupName="unitPriceGroup">
                        <app-dqn-input-number
                          class="max-w-[80%]"
                          [required]="
                  this.orderingInformationForm.get('billingFrequencyPriceGroup.billingFrequencyPrice').value === null ||
                  this.orderingInformationForm.get(
                    'billingFrequencyPriceGroup.billingFrequencyPrice'
                  )?.value === ''
                "
                          label="Einmalpreis"
                          [value]="this.orderingInformationForm.get('unitPriceGroup').get('unitPrice').value"
                          (numberChange)="updateUnitPrice($event)">
                        </app-dqn-input-number>

                        <dqn-form-field class="max-w-20%">
                            <dqn-label>Währung</dqn-label>
                            <dqn-dropdown
                              formControlName="currencyCodeId"
                              [required]="!!this.orderingInformationForm.get('unitPriceGroup').get('unitPrice').value">
                                <dqn-option
                                  *ngFor="let currencyCode of currencyCodes$ | async"
                                  [value]="currencyCode.id">
                                    {{ currencyCode.display_name }}
                                </dqn-option>
                            </dqn-dropdown>
                        </dqn-form-field>
                    </div>

                    <div
                      class="flex gap-1"
                      formGroupName="billingFrequencyPriceGroup">
                        <app-dqn-input-number
                          class="max-w-[80%]"
                          [required]="
                              this.orderingInformationForm.get('unitPriceGroup').get('unitPrice').value === null
                              || this.orderingInformationForm.get('unitPriceGroup').get('unitPrice').value === ''"
                          label="Abrechnungspreis"
                          [value]="this.orderingInformationForm.get('billingFrequencyPriceGroup').get('billingFrequencyPrice').value"
                          (numberChange)="updateBillingFrequencyPrice($event)">
                        </app-dqn-input-number>

                        <dqn-form-field class="max-w-[20%]">
                            <dqn-label>Währung</dqn-label>
                            <dqn-dropdown
                              formControlName="billingFrequencyCurrencyCodeId"
                              [required]="!!this.orderingInformationForm.get('billingFrequencyPriceGroup').get('billingFrequencyPrice').value">
                                <dqn-option
                                  *ngFor="let currencyCode of currencyCodes$ | async"
                                  [value]="currencyCode.id">
                                    {{ currencyCode.display_name }}
                                </dqn-option>
                            </dqn-dropdown>
                        </dqn-form-field>

                        <dqn-form-field class="max-w-[20%]">
                            <dqn-label>Abrechnungshäufigkeit</dqn-label>
                            <dqn-dropdown
                              formControlName="billingFrequencyId"
                              [required]="!!this.orderingInformationForm.get('billingFrequencyPriceGroup.billingFrequencyPrice')?.value">
                                <dqn-option
                                  *ngFor="let billingFrequency of billingFrequencies$ | async"
                                  [value]="billingFrequency.id">
                                    {{ billingFrequency.display_name }}
                                </dqn-option>
                            </dqn-dropdown>
                        </dqn-form-field>
                    </div>
                </form>
            </mat-expansion-panel>
        </div>
    </mat-card-content>
</mat-card>
