<ng-container *ngIf="(state$ | async) === ProposalState.landingPage">
    <div class="landing-page">
        <div class="mat-headline-5">Hallo {{ (user$ | async)?.name }}</div>
        <div class="mat-headline-6">
            Vielen Dank, dass Sie dabei helfen unseren Einkauf gemeinsam smart zu
            machen!
        </div>
        <img
          class="mb-2"
          src="../../../../../assets/fill_forms_yltj.svg"
          alt=""
        />
        <app-mat-card-alert
          text="In den folgenden Schritten werden Sie strukturiert und schnell durch den Prozess zur Artikelanlage geführt. Die gesamte Dauer um einen Artikel vorzuschlagen benötigt im Durchschnitt 10 Minuten."
          icon="info"
          [mode]="MatCardAlertMode.accent">
        </app-mat-card-alert>
        <button
          class="!mt-6"
          mat-raised-button
          color="primary"
          (click)="createProposal()">
            Einen neuen Artikel vorschlagen
        </button>
    </div>
</ng-container>

<ng-container *ngIf="(state$ | async) === ProposalState.stepper">
    <mat-card>
        <mat-card-content>
            <mat-horizontal-stepper
              linear
              #stepper>
                <mat-step
                  [stepControl]="selectOrCreateSupplierComponent.form"
                  label="Lieferant wählen">
                    <app-mat-card-alert
                      text="Im ersten Schritt wählen Sie Bitte den Lieferanten aus der Sie mit dem Artikel beliefert, welchen Sie vorschlagen möchten. Falls der Lieferant nicht in der Liste auftaucht können Sie ganz einfach einen neuen Lieferanten anlegen."
                      icon="info"
                      [mode]="MatCardAlertMode.accent"
                      class="my-2">
                    </app-mat-card-alert>

                    <div class="mt-2 flex">
                        <div class="flex-1 max-w-[80%]">
                            <app-step-select-or-create-supplier #selectOrCreateSupplierComponent></app-step-select-or-create-supplier>
                        </div>
                        <div class="ml-2 flex-1 max-w-[20%]">
                            <app-help-info-box assetPath="deliveries_131a.svg"></app-help-info-box>
                        </div>
                    </div>

                    <div class="mt-2">
                        <button
                          mat-button
                          matStepperNext
                          color="primary">
                            Nächster Schritt
                        </button>
                    </div>
                </mat-step>

                <mat-step
                  [completed]="selectProductCategoryComponent.isValid"
                  label="Kategorie auswählen">
                    <app-mat-card-alert
                      text='Bitte wählen Sie hier eine Kategorie aus. Durch diese Auswahl bestimmen Sie in in welcher Kategorie Ihr Artikel auf der Einkaufsplattform erscheint. Üblicherweise sortieren Sie Ihren Artikel so genau wie möglich ein. Sollte Ihre gewünschte Kategorie nicht verfügbar sein, so wählen Sie bitte "Sonstiges".'
                      icon="info"
                      [mode]="MatCardAlertMode.accent"
                      class="my-2">
                    </app-mat-card-alert>

                    <div class="mt-2 flex">
                        <div class="flex-1 max-w-[80%]">
                            <app-step-select-product-category #selectProductCategoryComponent></app-step-select-product-category>
                        </div>
                        <div class="ml-2 flex-1 max-w-[20%]">
                            <app-help-info-box assetPath="information_tab_ocka.svg"></app-help-info-box>
                        </div>
                    </div>

                    <div class="mt-2 flex gap-1">
                        <button
                          mat-button
                          matStepperPrevious
                          color="primary">
                            Zurück
                        </button>
                        <button
                          mat-button
                          matStepperNext
                          color="primary">
                            Nächster Schritt
                        </button>
                    </div>
                </mat-step>

                <mat-step
                  [completed]="(createSupplierProductComponent.isValid$ | async) === true"
                  label="Artikel vorschlagen">
                    <app-mat-card-alert
                      text="Geben Sie in diesem Schritt bitte alle artikelbezogenen Informationen ein. Je vollständiger und genauer Sie die Informationen angeben, desto besser. Idealerweise können Sie hier die Informationen aus bestehenden Quellen per kopieren und einfügen ausfüllen."
                      icon="info"
                      [mode]="MatCardAlertMode.accent"
                      class="my-2">
                    </app-mat-card-alert>

                    <div class="mt-2 flex">
                        <div class="flex-1 max-w-[80%]">
                            <app-step-create-supplier-product #createSupplierProductComponent></app-step-create-supplier-product>
                        </div>
                        <div class="ml-2 flex-1 max-w-[20%]">
                            <app-help-info-box assetPath="information_tab_ocka.svg"></app-help-info-box>
                        </div>
                    </div>

                    <div class="mt-2 flex gap-1">
                        <button
                          mat-button
                          matStepperPrevious
                          color="primary">
                            Zurück
                        </button>
                        <button
                          mat-button
                          (click)="proposalSupplierProduct()"
                          color="primary"
                          [disabled]="(canPropose$ | async) === false || (createSupplierProductComponent.isValid$ | async) === false">
                            Artikel einreichen
                        </button>
                    </div>
                </mat-step>
            </mat-horizontal-stepper>
        </mat-card-content>
    </mat-card>
</ng-container>

<ng-container *ngIf="(state$ | async) === ProposalState.finished">
    <div class="landing-page">
        <div class="mat-headline-5">Geschafft!</div>
        <div class="mat-headline-6">
            Sie haben für den Lieferanten
            <b class="underline">{{ (supplier$ | async)?.name }}</b> den Artikel
            <b class="underline">{{ (product$ | async)?.name }}</b> angelegt.
        </div>

        <div class="mat-headline-6">Vielen Dank!</div>
        <img
          class="mb-2"
          src="../../../../../assets/done_a34v.svg"
          alt=""
        />
        <div class="mt-6 flex gap-1 justify-center">
            <button
              mat-button
              color="primary"
              (click)="navigateToHome()">
                Zurück zur Startseite
            </button>
            <button
              class="ml-2"
              mat-flat-button
              color="primary"
              (click)="reset()">
                Noch einen Artikel vorschlagen
            </button>
        </div>
    </div>
</ng-container>
