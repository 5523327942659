import {mapGraphUsersToDqnOptions} from '@/shared/state/microsoft-graph-users/helpers/map-graph-users-to-dqn-options';
import {MicrosoftGraphUsersQuery} from '@/shared/state/microsoft-graph-users/microsoft-graph-users.query';
import {MicrosoftGraphUsersService} from '@/shared/state/microsoft-graph-users/microsoft-graph-users.service';
import {ChangeDetectionStrategy, Component, inject, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {SupplierProduct} from '../../../../state/supplier-products/supplier-product.model';

export type SupplierProductShareDialogData = {
  supplierProduct: SupplierProduct;
  recipients: string[];
  message: string;
}

export type SupplierProductShareDialogOutputData = {
  recipients: string[];
  message: string;
} | undefined;

@Component({
  selector: 'app-supplier-product-share-dialog',
  templateUrl: './supplier-product-share-dialog.component.html',
  styleUrls: ['./supplier-product-share-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SupplierProductShareDialogComponent implements OnInit {
  readonly microsoftGraphUsersQuery = inject(MicrosoftGraphUsersQuery);

  private readonly data = inject<SupplierProductShareDialogData>(MAT_DIALOG_DATA);
  private readonly dialogRef: MatDialogRef<SupplierProductShareDialogData, SupplierProductShareDialogOutputData> = inject(MatDialogRef);
  private readonly microsoftGraphUsersService = inject(MicrosoftGraphUsersService);

  microsoftGraphUsersAsDqnOptions$ = this.microsoftGraphUsersQuery.microsoftGraphUsersWithEmail$.pipe(
    mapGraphUsersToDqnOptions(),
  );

  get supplierProduct() {
    return this.data.supplierProduct;
  }

  get supplierProductName() {
    return this.supplierProduct.product?.name;
  }

  shareForm = new FormGroup({
    recipients: new FormControl(this.data.recipients, Validators.required),
    message: new FormControl(this.data.message, Validators.required),
  });

  ngOnInit() {
    this.microsoftGraphUsersService.getOnlyWithMailAccounts().subscribe();
  }

  cancel() {
    this.dialogRef.close();
  }

  shareSupplierProduct() {
    this.dialogRef.close(this.shareForm.getRawValue());
  }
}
