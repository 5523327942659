<mat-card>
    <img
      mat-card-image
      [src]="imageSourcePath"
      alt="" />
    <mat-card-content class="mt-2">
        <div class="mat-subtitle-1">Benötigen Sie Hilfe?</div>
        <div class="mat-subtitle-2">
            Unser strategischer Einkauf steht jederzeit für Fragen und Unterstützung
            zur Verfügung.
        </div>
    </mat-card-content>

    <mat-card-actions>
        <a
          mat-button
          href="mailto:{{(sessionQuery.tenantSupportContactMail$ | async)}}">
            E-Mail
        </a>
    </mat-card-actions>
</mat-card>
