import {getMatDialogConfig} from '@/shared/utils/functions/mat-dialog';
import {ChangeDetectionStrategy, Component, inject, Input} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {UntilDestroy} from '@ngneat/until-destroy';
import {catchError, filter, switchMap, tap} from 'rxjs/operators';
import {SupplierProduct} from '../../state/supplier-products/supplier-product.model';
import {SupplierProductsService} from '../../state/supplier-products/supplier-products.service';
import {
  SupplierProductShareDialogComponent,
  SupplierProductShareDialogData
} from './components/supplier-product-share-dialog/supplier-product-share-dialog.component';

@UntilDestroy()
@Component({
  selector: 'app-supplier-product-share-icon',
  templateUrl: './supplier-product-share-icon.component.html',
  styleUrls: ['./supplier-product-share-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SupplierProductShareIconComponent {
  private readonly supplierProductsService = inject(SupplierProductsService);
  private readonly snackBar = inject(MatSnackBar);
  private readonly dialog = inject(MatDialog);

  @Input({required: true}) supplierProduct!: SupplierProduct;

  shareSupplierProduct() {
    this.openShareDialog().pipe(
      tap(() => this.snackBar.open(
        'Das Produkt wurde erfolgreich geteilt!',
        'Schließen',
        {panelClass: 'snackbar-success'})
      ),
    ).subscribe();
  }

  private openShareDialog(initialData: Partial<SupplierProductShareDialogData> | null = null) {
    const config = getMatDialogConfig<SupplierProductShareDialogData>({
      autoFocus: 'dialog',
      data: {
        supplierProduct: this.supplierProduct,
        recipients: initialData?.recipients ?? [],
        message: initialData?.message ?? '',
      },
    });

    return this.dialog.open(SupplierProductShareDialogComponent, config).afterClosed().pipe(
      filter(result => !!result),
      switchMap(({recipients, message}) =>
        this.supplierProductsService.shareSupplierProduct(this.supplierProduct, recipients, message).pipe(
          catchError(() => {
            return this.openShareDialog({recipients, message});
          }),
        )
      ),
    );
  }
}
