import {Product} from '@/management/product/state/product.model';
import {ProductsQuery} from '@/management/product/state/products.query';
import {ProductsService} from '@/management/product/state/products.service';
import {ProductForm} from '@/shared/components/product-form/types/product-form';
import {FormMode} from '@/shared/enums/form-mode.enum';
import {BillingFrequenciesQuery} from '@/shared/state/billing-frequencies/billing-frequencies.query';
import {BillingFrequenciesService} from '@/shared/state/billing-frequencies/billing-frequencies.service';
import {BillingFrequency} from '@/shared/state/billing-frequencies/billing-frequency.model';
import {CurrencyCode} from '@/shared/state/currency-codes/currency-code.model';
import {CurrencyCodesQuery} from '@/shared/state/currency-codes/currency-codes.query';
import {CurrencyCodesService} from '@/shared/state/currency-codes/currency-codes.service';
import {
  billingFrequencyPriceNotEmptyThenCurrencyCodeIdAndBillingFrequencyIdRequiredValidator,
  unitPriceNotEmptyThenCurrencyCodeIdRequiredValidator,
  unitPriceOrBillingFrequencyPriceRequiredValidator
} from '@/shared/validators';
import {createSupplierProduct} from '@/shop/product/state/supplier-products/supplier-product.model';
import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {DqnSingleOption} from '@dqn/components/combobox';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {combineLatest, Observable} from 'rxjs';
import {map, startWith, switchMap, tap} from 'rxjs/operators';
import {
  SupplierProductAvailableStatusesService
} from '../../../../state/supplier-product-available-statuses/supplier-product-available-statuses.service';
import {ProposalQuery, ProposalStore} from '../../state/proposal.state';

@UntilDestroy()
@Component({
  selector: 'app-step-create-supplier-product',
  templateUrl: './step-create-supplier-product.component.html',
  styleUrls: ['./step-create-supplier-product.component.scss']
})
export class StepCreateSupplierProductComponent implements OnInit {
  FormMode = FormMode;

  form!: UntypedFormGroup;
  orderingInformationForm!: UntypedFormGroup;
  disableCreateProduct!: boolean;

  products$!: Observable<DqnSingleOption[]>;
  currencyCodes$!: Observable<CurrencyCode[]>;
  billingFrequencies$!: Observable<BillingFrequency[]>;

  isValid$!: Observable<boolean>;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private store: ProposalStore,
    private query: ProposalQuery,
    // HINT: Using products state from management module in shop module
    private productsQuery: ProductsQuery,
    private productsService: ProductsService,
    private currencyCodesService: CurrencyCodesService,
    private currencyCodesQuery: CurrencyCodesQuery,
    private billingFrequenciesService: BillingFrequenciesService,
    private billingFrequenciesQuery: BillingFrequenciesQuery,
    private supplierProductAvailableStatusesService: SupplierProductAvailableStatusesService,
  ) {
  }

  ngOnInit(): void {
    this.initialize();

    this.productsService.get().subscribe();
    this.billingFrequenciesService.get().subscribe();
    this.currencyCodesService.get().subscribe();
    this.supplierProductAvailableStatusesService.get().subscribe();

    // Update product information
    this.form.get('productId').valueChanges.pipe(
      switchMap(productId => this.productsQuery.selectProductById(productId)),
      tap((value: Product) => this.store.updateProduct(value)),
      untilDestroyed(this),
    ).subscribe();

    // Update supplier product information
    this.orderingInformationForm.valueChanges.pipe(
      tap(({supplierOrderNumber, unitPriceGroup, billingFrequencyPriceGroup}) => {
        const unitPriceValue = unitPriceGroup?.unitPrice;
        const billingFrequencyPriceValue = billingFrequencyPriceGroup.billingFrequencyPrice;

        const supplierProduct = createSupplierProduct({
          supplier_order_number: supplierOrderNumber,
          unit_price: unitPriceValue !== null && unitPriceValue >= 0 ? unitPriceValue * 100 : null,
          currency_code_id: unitPriceGroup.currencyCodeId,
          billing_frequency_price: billingFrequencyPriceValue !== null && billingFrequencyPriceValue >= 0 ? billingFrequencyPriceValue * 100 : null,
          billing_frequency_currency_code_id: billingFrequencyPriceGroup?.billingFrequencyCurrencyCodeId,
          billing_frequency_id: billingFrequencyPriceGroup?.billingFrequencyId,
        });

        this.store.updateSupplierProduct(supplierProduct);
      }),
      untilDestroyed(this),
    ).subscribe();
  }

  updateUnitPrice(unitPrice: number) {
    this.orderingInformationForm.get('unitPriceGroup.unitPrice').patchValue(unitPrice);
  }

  updateBillingFrequencyPrice(billingFrequencyPrice: number) {
    this.orderingInformationForm.get('billingFrequencyPriceGroup.billingFrequencyPrice').patchValue(billingFrequencyPrice);
  }

  createProduct(productForm: ProductForm) {
    // Update category id based on previous category selection
    productForm = {
      ...productForm,
      product: {
        ...productForm.product,
        product_category_id: this.query.productCategoryId,
      }
    };

    this.productsService.addProductOnDb(productForm).pipe(
      tap(product => {
        this.form.get('productId').patchValue(product.id);
        this.form.updateValueAndValidity();
        this.disableCreateProduct = true;
      }),
    ).subscribe();
  }

  private initialize() {
    this.disableCreateProduct = false;

    this.currencyCodes$ = this.currencyCodesQuery.currencyCodes$;
    this.billingFrequencies$ = this.billingFrequenciesQuery.billingFrequencies$;
    this.products$ = this.query.productCategoryId$.pipe(
      switchMap(productCategoryId => this.productsQuery.selectProductsForCategoryAsDqnOptions(productCategoryId)),
    );

    this.initializeForms();
  }

  private initializeForms() {
    this.form = this.formBuilder.group({
      productId: [null, Validators.required]
    });

    this.orderingInformationForm = this.formBuilder.group({
        supplierOrderNumber: [
          '',
          [Validators.required],
        ],
        unitPriceGroup: this.formBuilder.group({
            unitPrice: null,
            currencyCodeId: null,
          },
          {validators: unitPriceNotEmptyThenCurrencyCodeIdRequiredValidator}
        ),
        billingFrequencyPriceGroup: this.formBuilder.group({
            billingFrequencyPrice: null,
            billingFrequencyCurrencyCodeId: null,
            billingFrequencyId: null,
          },
          {validators: billingFrequencyPriceNotEmptyThenCurrencyCodeIdAndBillingFrequencyIdRequiredValidator}
        ),
      },
      {validators: unitPriceOrBillingFrequencyPriceRequiredValidator}
    );

    this.isValid$ = combineLatest([
      this.orderingInformationForm.statusChanges,
      this.form.statusChanges,
    ]).pipe(
      map(([orderInformationStatus, formStatus]) => orderInformationStatus === 'VALID' && formStatus === 'VALID'),
      startWith(false),
    );
  }
}

