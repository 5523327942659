<div class="mat-typography mb-4">
    <div>
        <div class="flex flex-col items-center justify-center">
            <div
              class="mat-headline-5"
              mat-dialog-title>
                Artikel "<span>{{ supplierProductName }}</span>" teilen
            </div>
        </div>
    </div>

    <div>
        <form [formGroup]="shareForm">
            <div class="flex flex-col">
                <ng-container *ngIf="(microsoftGraphUsersQuery.isLoading$ | async) === false; else microsoftGraphUsersLoading">
                    <dqn-form-field
                      *ngIf="(microsoftGraphUsersAsDqnOptions$ | async) as microsoftGraphUsersAsDqnOptions"
                      class="w-full">
                        <dqn-label>Nutzerauswahl</dqn-label>

                        <dqn-combobox
                          formControlName="recipients"
                          multiple
                          [options]="microsoftGraphUsersAsDqnOptions" />

                        <dqn-error *ngIf="shareForm.controls.recipients.touched && shareForm.controls.recipients.hasError('required')">
                            Bitte wählen Sie Kollegen aus.
                        </dqn-error>
                    </dqn-form-field>
                </ng-container>

                <ng-template #microsoftGraphUsersLoading>
                    <p>Die Benutzer werden geladen ...</p>
                </ng-template>

                <dqn-form-field
                  class="w-full"
                  hintText="Maximale Länge: 255 Zeichen">
                    <dqn-label>Kommentar</dqn-label>

                    <textarea
                      #descriptionInput
                      dqnInput
                      formControlName="message"
                      required
                      rows="4"
                      maxlength="255">
                    </textarea>

                    <mat-hint align="end">{{ descriptionInput.value?.length || 0 }}/255</mat-hint>
                </dqn-form-field>
            </div>
        </form>
    </div>

    <mat-dialog-actions
      align="center"
      class="flex justify-center items-center gap-2">
        <button
          (click)="shareSupplierProduct()"
          [disabled]="shareForm.invalid"
          color="accent"
          class="flex-grow"
          mat-flat-button>
            Teilen
        </button>

        <button
          (click)="cancel()"
          class="flex-grow !ml-0"
          color="accent"
          mat-button>Abbrechen
        </button>
    </mat-dialog-actions>
</div>
