<app-bread-crumbs [breadCrumbs]="breadCrumbs$ | async" />

<ng-container *ngIf="(productsQuery.activeProduct$ | async) as product"> <!-- HINT: Will always be a top level product -->
    <ng-container *ngIf="(supplierProductsQuery.activeSupplierProduct$ | async) as supplierProduct; else noSupplierProduct">
        <ng-container *ngIf="(productsQuery.activeProductOrVariantProduct$ | async) as activeProductOrVariantProduct"> <!-- HINT: Used exclusively for variants with a parent product -->
            <div class="main-product flex flex-wrap mt-2">
                <div class="flex-1 basis-full max-w-[100%] xl:max-w-[45%] 2xl:max-w-[50%] xl:px-4">
                    <div class="main-product-image w-full h-full flex justify-center items-start">
                        <img
                          [src]="activeProductOrVariantProduct.images | productDefaultImageUrl"
                          [alt]="activeProductOrVariantProduct.name" />
                    </div>
                </div>

                <div class="flex-1 basis-full max-w-[100%] xl:max-w-[45%] 2xl:max-w-[35%]">
                    <div class="flex gap-1 mt-2">
                        <span class="mat-headline-5 overflow-wrap">{{ activeProductOrVariantProduct.name }}</span>

                        <!-- Favorite status is always for virtual product or normal product -->
                        <app-product-favorite-icon [product]="product" />

                        <app-supplier-product-share-icon [supplierProduct]="supplierProductsQuery.activeSupplierProduct$ | async" />
                    </div>

                    <div>
                        <span class="eco">
                          <ng-container *ngFor="let i of [1, 2, 3, 4]">
                            <mat-icon fontSet="material-icons-round">eco</mat-icon>
                          </ng-container>
                          <ng-container *ngFor="let i of [1]">
                            <mat-icon fontSet="material-icons-outlined">eco</mat-icon>
                          </ng-container>
                        </span>
                        <span>Unsere Nachhaltigkeitsbewertung</span>
                    </div>

                    <div class="eco-logos">
                        <img
                          src="../../../../../assets/logo_rainforest.png"
                          alt="" />
                        <img
                          src="../../../../../assets/logo_handinhand.png"
                          alt="" />
                        <img
                          src="../../../../../assets/logo_naturland.png"
                          alt="" />
                    </div>

                    <div
                      *ngIf="activeProductOrVariantProduct.keywords ?? product.keywords as productKeywords"
                      class="keywords">
                        <mat-chip-list>
                            <mat-chip
                              *ngFor="let keyword of productKeywords.split(',')"
                              color="primary">
                                {{ keyword }}
                            </mat-chip>
                        </mat-chip-list>
                    </div>

                    <div
                      class="attributes"
                      *ngIf="(productsQuery.productAttributesForActiveProduct$ | async) as productAttributesForActiveProduct">
                        <span>Herstellerangaben</span>

                        <div class="table-product-attributes flex">
                            <table
                              class="flex-1"
                              mat-table
                              [dataSource]="productAttributesForActiveProduct"
                              [trackBy]="trackByProductAttributeId">
                                <ng-container matColumnDef="attribute">
                                    <td
                                      mat-cell
                                      *matCellDef="let element">
                                        {{ (element.product_attribute_template_id | productAttributeTemplateById$ | async)?.display_name }}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="value">
                                    <td
                                      mat-cell
                                      *matCellDef="let element">
                                        {{ element.value | parseProductAttributeValue }}
                                        {{ (element.product_attribute_template_id | productAttributeTemplateById$ | async)?.quantity_unit?.display_name }}
                                    </td>
                                </ng-container>

                                <tr
                                  mat-row
                                  *matRowDef="let row; columns: displayedColumns"></tr>
                            </table>
                        </div>
                    </div>

                    <div class="mt-2">
                        <span class="subheader">Bezugsquelle</span>

                        <div>
                            <ng-container *ngIf="(supplierProduct.supplier_id | supplierById | async)?.name as supplierName; else noSupplier">
                                {{ supplierName }}
                            </ng-container>

                            <ng-template #noSupplier>
                                Keine Bezugsquelle hinterlegt.
                            </ng-template>
                        </div>
                    </div>

                    <mat-expansion-panel
                      *ngIf="product.description ?? activeProductOrVariantProduct.description as productDescription"
                      class="embedded mat-elevation-z0"
                      expanded="true">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <div class="mat-headline-6">Artikelbeschreibung</div>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <p>{{ productDescription }}</p>
                    </mat-expansion-panel>

                    <mat-expansion-panel
                      *ngIf="product.description_long ?? activeProductOrVariantProduct.description_long as productDescriptionLong"
                      class="embedded mat-elevation-z0">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <div class="mat-headline-6">Weitere Informationen</div>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <p>{{ productDescriptionLong }}</p>
                    </mat-expansion-panel>

                    <app-product-variant-selection
                      *ngIf="(productsQuery.hasActiveProductVariants$ | async)"
                      [variants]="(productsQuery.variantsForActiveProduct$ | async)"
                      [variantAttributes]="(productsQuery.variantAttributesForActiveProduct$ | async)"
                      [initialSelection]="(productsQuery.preferredVariantForActiveProduct$ | async)"
                      [loading]="(productLoading$ | async)"
                      (selectVariant)="selectVariant($event)" />

                    <div class="my-2">
                        <div
                          *ngIf="(product | hasProductContentsInformation) || (activeProductOrVariantProduct | hasProductContentsInformation)"
                          class="product-unit">
                            Inhalt:
                            <ng-container *ngIf="product | hasProductContentsInformation; else noDirectContents">
                                {{ product | productUnitDisplayText }}
                            </ng-container>

                            <ng-template #noDirectContents>
                                {{ activeProductOrVariantProduct | productUnitDisplayText }}
                            </ng-template>
                        </div>

                        <app-supplier-product-price
                          [supplierProduct]="supplierProduct"
                          [showScalePrice]="true"
                          [useStyling]="true">
                        </app-supplier-product-price>

                        <div *ngIf="isShopActive">
                            <dqn-form-field class="mt-2">
                                <dqn-label>Menge</dqn-label>
                                <dqn-dropdown [(ngModel)]="quantity">
                                    <dqn-option
                                      *ngFor="let quantity of quantities"
                                      [value]="quantity">
                                        {{ quantity }} {{ product?.quantity_unit?.display_name }}
                                    </dqn-option>
                                </dqn-dropdown>
                            </dqn-form-field>
                        </div>
                        <div *ngIf="isShopActive">
                            <button
                              type="submit"
                              mat-raised-button
                              color="primary"
                              (click)="addItemToShoppingCart(supplierProduct.id)"
                              [disabled]="(productLoading$ | async)">
                                <mat-icon>shopping_cart</mat-icon>
                                In den Einkaufswagen
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <app-supplier-products-product-list
              *ngIf="supplierProductsQuery.supplierProductsWithSameBaseProductForActiveSupplierProduct$ | async as supplierProductsWithSameBaseProduct"
              [product]="activeProductOrVariantProduct"
              [loading]="supplierProductsQuery.isLoading$ | async"
              [selectedSupplierProduct]="supplierProduct"
              [supplierProducts]="supplierProductsWithSameBaseProduct"
              (addToShoppingCart)="addItemToShoppingCart($event.id)" />
        </ng-container>
    </ng-container>

    <ng-template #noSupplierProduct>
        <p>Dieser Artikel existiert nicht.</p>
    </ng-template>

    <div class="alternative-products">
        <div class="mat-headline-6">
            Weitere Artikel aus der Kategorie
            {{ (productCategoriesQuery.activeProductCategory$ | async)?.display_name }}
        </div>

        <ng-container *ngIf="productsQuery.hasProductsForActiveProductCategory$ | async; else noSupplierProductsForActiveProductCategory">
            <app-product-gallery [products]="productsQuery.productsForActiveProductCategoryWithoutActiveProduct$ | async" />
        </ng-container>

        <ng-template #noSupplierProductsForActiveProductCategory>
            Zu dieser Kategorie existieren keine weiteren Artikel.
        </ng-template>
    </div>
</ng-container>
